<template>
  <v-dialog
      v-model="dialogTransfer"
      max-width="800px"
      persistent
  >
    <template>
      <v-card>
        <v-toolbar
            color="primary"
            dark
        >Waffen Überlassung
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="scrollable-content">
          <v-card-text>
            <div id="UeberlassungsFormular" ref="UeberlassungsFormular">
              <div class=WordSection1>

                <p class="MsoNormal" align=center style='text-align:center'><b>Vorübergehende
                  Überlassung erlaubnispflichtiger Schusswaffen <br> im Rahmen des § 12 Abs. 1 Ziffer
                  1 Buchstabe a oder b Waffengesetz</b></p>

                <br>
                <p class="MsoNormal"><b>Der Waffenbesitzkarteninhaber </b></p>
                (Überlasser)<br>
                <br>


                <p class=MsoNormal><b>Name, Vorname:</b> {{ data.MyName }} </p>


                <p class=MsoNormal><b>Anschrift: </b> {{ data.MyAddress }}</p>


                <p class=MsoNormal><b>PLZ / Ort: </b>{{ data.MyPLZ_ORT }}</p>

                <p class=MsoNormal><b>NWR-ID Person: </b>{{ data.MyNWRPID }}</p>

                <br>
                <p class=MsoNormal><b>überlässt vorübergehend, längstens für die Dauer von vier<br>
                  Wochen an den Inhaber einer Waffenbesitzkarte </b></p>
                (vorübergehender Erwerber und Besitzer)<br>


                <br>
                <p class=MsoNormal><b>Name, Vorname: </b> {{ data.RecName + ', ' + data.RecVorname }}</p>

                <p class=MsoNormal><b>Anschrift: </b>{{ data.RecAddress }}</p>

                <p class=MsoNormal><b>PLZ, Ort: </b> {{ data.RecPLZ + ', ' + data.RecOrt }}</p>

                <br>
                <p class=MsoNormal><b>nachfolgende Schusswaffe:</b></p>


                <br>
                <table class=MsoTableGrid border=1 cellspacing=0 cellpadding=0
                       style='border-collapse:collapse;border:none'>
                  <tr>
                    <td style='width:113.15pt;border:solid windowtext 1.0pt;
                    padding:0cm 5.4pt 0cm 5.4pt'>
                      <p class=MsoNormal><b>aus der WBK des Überlassers Nr.</b></p>
                      <p class=MsoNormal><b>&nbsp;</b></p>
                    </td>
                    <td style='width:4.0cm;border:solid windowtext 1.0pt;
                    border-left:none;padding:0cm 5.4pt 0cm 5.4pt'>
                      <p class=MsoNormal><b>Kaliber</b></p>
                    </td>
                    <td style='width:4.0cm;border:solid windowtext 1.0pt;
                    border-left:none;padding:0cm 5.4pt 0cm 5.4pt'>
                      <p class=MsoNormal><b>Hersteller oder Warenzeichen</b></p>
                      <p class=MsoNormal><b>(Modell)</b></p>
                    </td>
                    <td style='width:113.1pt;border:solid windowtext 1.0pt;
                      border-left:none;padding:0cm 5.4pt 0cm 5.4pt'>
                      <p class=MsoNormal><b>Herstellungs-nummer</b></p>
                    </td>
                    <td style='width:113.1pt;border:solid windowtext 1.0pt;
                      border-left:none;padding:0cm 5.4pt 0cm 5.4pt'>
                      <p class=MsoNormal><b>NWR-ID Waffe</b></p>
                    </td>
                  </tr>
                  <tr>
                    <td style='width:113.15pt;border:none;border-bottom:
                    solid windowtext 1.0pt;padding:0cm 5.4pt 0cm 5.4pt'>
                      <p class=MsoNormal><b>&nbsp;</b></p>
                    </td>
                    <td style='width:4.0cm;border:none;border-bottom:solid windowtext 1.0pt;
                      padding:0cm 5.4pt 0cm 5.4pt'>
                      <p class=MsoNormal><b>&nbsp;</b></p>
                    </td>
                    <td style='width:4.0cm;border:none;border-bottom:solid windowtext 1.0pt;
                      padding:0cm 5.4pt 0cm 5.4pt'>
                      <p class=MsoNormal><b>&nbsp;</b></p>
                    </td>
                    <td style='width:113.1pt;border:none;border-bottom:solid windowtext 1.0pt;
                      padding:0cm 5.4pt 0cm 5.4pt'>
                      <p class=MsoNormal><b>&nbsp;</b></p>
                    </td>
                    <td style='width:113.1pt;border:none;border-bottom:solid windowtext 1.0pt;
                      padding:0cm 5.4pt 0cm 5.4pt'>
                      <p class=MsoNormal><b>&nbsp;</b></p>
                    </td>
                  </tr>
                  <tr>
                    <td style='width:113.15pt;border:solid windowtext 1.0pt;
                      border-top:solid windowtext 1.0pt;padding:0cm 5.4pt 0cm 5.4pt'>
                      <p class=MsoNormal><b>{{ data.WBK_NR }}</b></p>
                    </td>
                    <td style='width:4.0cm;border-top:none;border-left:none;
                      border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                      padding:0cm 5.4pt 0cm 5.4pt'>
                      <p class=MsoNormal><b>{{ data.Kaliber }}</b></p>
                    </td>
                    <td style='width:4.0cm;border-top:none;border-left:none;
                      border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                      padding:0cm 5.4pt 0cm 5.4pt'>
                      <p class=MsoNormal><b>{{ data.Hersteller }}</b></p>
                    </td>
                    <td style='width:113.1pt;border-top:none;border-left:
                      none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                      padding:0cm 5.4pt 0cm 5.4pt'>
                      <p class=MsoNormal><b>{{ data.Nummer }}</b></p>
                    </td>
                    <td style='width:113.1pt;border-top:none;border-left:
                      none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                      padding:0cm 5.4pt 0cm 5.4pt'>
                      <p class=MsoNormal><b>{{ data.NWR_WID }}</b></p>
                    </td>
                  </tr>

                </table>

                <p class=MsoNormal><b>&nbsp;</b></p>

                <p class=MsoNormal><b>&nbsp;</b></p>

                <p class=MsoNormal><b>für einen vom Bedürfnis umfassten Zweck bzw. im
                  Zusammenhang damit, vorübergehend zum Zweck der sicheren Verwahrung oder der
                  Beförderung.</b></p>
                <br>

                <p class=MsoNormal><b>Die Waffe und die Munition dürfen nicht an Dritte
                  überlassen werden.
                  <br>
                  Der Empfänger der Waffe erhält eine Kopie der
                  Waffenbesitzkarte.
                  <br>
                  Der Empfänger der Waffe wird auf § 12 Abs. 1, Ziffer 1a
                  und b, Ziffer 5, Abs. 2 Ziffer 1 sowie § 36 WaffG hingewiesen.</b></p>


                <br>
                <p class=MsoNormal><span style='font-size:11.0pt'>Spätestens binnen Monatsfrist
                (gem. §12 Abs.1 Nr.1a) ist die Eintragung der Waffe in die WBK des Leihnehmers
                und der Austrag aus der aus der WBK des Entleihers bei der jeweils zuständigen
                Waffenbehörde zu beantragen. Eine Weitergabe der Waffe an Dritte ist nicht
                gestattet. Dieses Formular ist gem. §38 Nr.1. WaffG beim Führen der Leihwaffe
                mit zu führen; und bitte auch die Kopie der WBK des Entleihers. </span></p>

                <br><br>
                <v-row class="mt-8">
                  <v-col>Datum, Unterschrift des Überlassers</v-col>
                  <v-col>Datum, Unterschrift des Empfängers</v-col>
                </v-row>

              </div>
            </div>
          </v-card-text>
        </div>
        <v-divider></v-divider>
        <v-card-actions class="justify-end">
          <v-btn
              text
              @click="closeDialog"
          >abbrechen
          </v-btn>
          <v-btn
              text
              @click="TransferWeaponPrint"
          >drucken
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
// import jsPDF from "jspdf";
export default {
  name: 'weaponTransferProtokoll',
  props: {
    dialogTransfer: {
      type: Boolean
    },
    dialog: {
      type: Boolean
    },
    data: {
      type: Object
    }
  },
  methods: {
    async TransferWeaponPrint() {
      await this.$htmlToPaper('UeberlassungsFormular');
      this.TransferWeapon_into_DB();
    },
    closeDialog() {
      this.$emit('clicked', false)
    },
    TransferWeapon_into_DB() {
      if (this.data.RecObject) {

        var myDate = this.data.RecTransferUntil.split(".");
        var newDate = new Date(myDate[2], myDate[1] - 1, myDate[0]);

        let Data = {
          weapon_id: this.data.MyWeaponID,
          transfer_until: newDate,
          transfer_delete: 0
        }
        if (this.data.RecObject.club_id) {
          let Temp = {
            to_club_id: this.data.RecObject.club_id
          }
          Data = Object.assign(Data, Temp);
        } else if (this.data.RecObject.user_id) {
          let Temp = {
            to_user_id: this.data.RecObject.user_id
          }
          Data = Object.assign(Data, Temp);
        }
        this.$store.dispatch('GeneralApp/weapon_transfer', Data)
            .then(this.closeDialog())

      }
    },
    // makeTransferPDF(item) {
    //   var source = item;
    //   let rows = [];
    //   let columnHeader = ['Verein', 'Aktivität', 'Stand', 'Standaufsicht', 'Waffe', 'Disziplin', 'Begin', 'Ende', 'bestätigt Verein'];
    //   let pdfName = 'Überlassung von ' + this.$store.state.UserAuth.user.vorname + ' ' + this.$store.state.UserAuth.user.name;
    //   source.items.forEach(element => {
    //     var temp = [
    //       element.name,
    //       element.aktivitaet,
    //       element.KurzBez,
    //       element.Aufsicht,
    //       element.KurzT,
    //       element.LangBez,
    //       new Date(element.beginn).toLocaleString(),
    //       new Date(element.ende).toLocaleString(),
    //       element.bestaetigung_holen,
    //     ];
    //     rows.push(temp);
    //   });
    //   var doc = new jsPDF();
    //   doc.autoTable(columnHeader, rows, {
    //
    //     startY: 40,
    //     margin: {horizontal: 10},
    //     styles: {overflow: "linebreak"},
    //     bodyStyles: {valign: "top"},
    //     columnStyles: {email: {cellWidth: "wrap"}},
    //     theme: "striped",
    //     showHead: "everyPage",
    //     didDrawPage: function (data) {
    //
    //       // Header
    //       doc.setFontSize(20);
    //       doc.setTextColor(40);
    //       doc.text(pdfName, data.settings.margin.left, 22);
    //       doc.setFontSize(12);
    //       doc.text("Erstellt am " + new Date().toLocaleString() + " von schuetzenbuch.de", data.settings.margin.left, 30);
    //
    //       // Footer
    //       var str = "Seite " + doc.internal.getNumberOfPages();
    //
    //       doc.setFontSize(10);
    //
    //       var pageSize = doc.internal.pageSize;
    //       var pageHeight = pageSize.height
    //           ? pageSize.height
    //           : pageSize.getHeight();
    //       doc.text(str, data.settings.margin.left, pageHeight - 10);
    //     }
    //   });
    //   doc.save(pdfName + '.pdf');
    //
    // },
  },

}
</script>

<style scoped>
/* Font Definitions */
@font-face {
  font-family: "Cambria Math";
  panose-1: 2 4 5 3 5 4 6 3 2 4;
}

/* Style Definitions */
p.MsoNormal, li.MsoNormal, div.MsoNormal {
  margin: 0cm;
  margin-bottom: .0001pt;
  font-size: 12.0pt;
  font-family: "Arial", sans-serif;
}

p.MsoHeader, li.MsoHeader, div.MsoHeader {
  mso-style-link: "Kopfzeile Zchn";
  margin: 0cm;
  margin-bottom: .0001pt;
  font-size: 12.0pt;
  font-family: "Arial", sans-serif;
}

p.MsoFooter, li.MsoFooter, div.MsoFooter {
  mso-style-link: "Fußzeile Zchn";
  margin: 0cm;
  margin-bottom: .0001pt;
  font-size: 12.0pt;
  font-family: "Arial", sans-serif;
}

span.KopfzeileZchn {
  mso-style-name: "Kopfzeile Zchn";
  mso-style-link: Kopfzeile;
  font-family: "Arial", sans-serif;
}

span.FuzeileZchn {
  mso-style-name: "Fußzeile Zchn";
  mso-style-link: Fußzeile;
  font-family: "Arial", sans-serif;
}

.MsoChpDefault {
  font-size: 10.0pt;
}

/* Page Definitions */
@page WordSection1 {
  size: 21.0cm 841.95pt;
  margin: 1.0cm 70.9pt 2.0cm 70.9pt;
}

div.WordSection1 {
  page: WordSection1;
}

.scrollable-content {
  max-height: 800px; /* Feste Höhe festlegen */
  overflow-y: auto; /* Scrollbar hinzufügen */
}
</style>