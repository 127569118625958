<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="weapon"
        sort-by="KurzT"
        class="elevation-1"
        :item-class="itemRowClass"
    >
      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-spacer></v-spacer>
          <v-dialog
              v-model="dialog"
              width="800px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon class="mr-2">mdi-pistol</v-icon>
                Neue Waffe
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span v-if="!dialogDiszi" class="text-h5">Neue Waffe <v-icon class="ml-4">mdi-pistol</v-icon></span>
                <span v-if="dialogDiszi" class="text-h5">Waffe bearbeiten <v-icon
                    class="ml-4">mdi-pistol</v-icon></span>
                <v-spacer></v-spacer>
                <v-btn icon @click="close">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-divider></v-divider>
              <div class="scrollable-content">

                <v-card-text>
                  <v-form ref="form" v-on:submit.prevent>
                    <v-container>
                      <span>(*) - Pflichtfelder</span>
                      <v-row class="mt-3">
                        <v-col>
                          <v-text-field
                              label="(*) Hersteller"
                              v-model="editedItem.Hersteller"
                              :rules="TextRules"
                              outlined
                          ></v-text-field>
                        </v-col>
                        <v-col>
                          <v-text-field
                              label="(*) Nummer"
                              v-model="editedItem.Nummer"
                              :rules="TextRules"
                              outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field
                              label="(*) Kaliber"
                              v-model="editedItem.Kaliber"
                              :rules="NWRIDRules"
                              outlined
                          ></v-text-field>
                        </v-col>
                        <v-col>
                          <v-text-field
                              label="(*) Kurztext (Verwendung in allen Ansichten)"
                              v-model="editedItem.KurzT"
                              :rules="TextRules"
                              outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-select
                              v-model="editedItem.Gattung"
                              :items="Gattung"
                              item-text="Waffen Gattung"
                              item-value="value"
                              label="(*) Waffen Gattung"
                              outlined
                              class="mx-1"
                          >
                            <template slot="selection" slot-scope="data">
                              <!-- HTML that describe how select should render selected items -->
                              {{ data.item.value }}
                            </template>
                            <template slot="item" slot-scope="data">
                              <!-- HTML that describe how select should render items when the select is open -->
                              {{ data.item.value }}
                            </template>

                          </v-select>
                        </v-col>
                        <v-col>
                          <v-dialog
                              ref="dialog"
                              v-model="modal"
                              :return-value.sync="editedItem.Erwerb"
                              persistent
                              width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                  v-model="editedItem.Erwerbformated"
                                  label="(*) Erwerb"
                                  append-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  :rules="DatumRules"
                                  outlined
                              ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="editedItem.Erwerb"
                                scrollable
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                  text
                                  color="primary"
                                  @click="modal = false"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.dialog.save(editedItem.Erwerb)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-dialog>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field
                              label="WBK Nummer"
                              v-model="editedItem.WBK"
                              outlined
                          ></v-text-field>
                        </v-col>
                        <v-col></v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field
                              label="NWR-Erlaubnis-ID"
                              v-model="editedItem.NWREID"
                              outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field
                              label="NWR-Waffen-ID"
                              v-model="editedItem.NWRWID"
                              outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          <v-text-field
                              label="Langtext"
                              v-model="editedItem.LangT"
                              outlined
                          ></v-text-field>
                        </v-col>
                        <v-col>
                          <v-text-field
                              label="Beschreibung"
                              v-model="editedItem.Beschreibung"
                              outlined
                          ></v-text-field>
                        </v-col>

                      </v-row>
                      <v-row
                          class="mb-5">
                        <v-col>
                          <v-switch
                              v-model="editedItem.export_weapon"
                              label="Export Waffe zu PDF"
                              color="primary"
                              hide-details
                          ></v-switch>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <div v-if="dialogDiszi">
                        <span class="text-h6 my-4">Disziplin zur Waffe hinzufügen:</span>
                        <v-row class="mt-3 mb-3">
                          <v-col>
                            <suchen_zuordnen
                                titel="Disziplin zuordnen"
                                btn_title="Disziplin zuordnen"
                                btn_icon="mdi-pistol"
                                :content="discipline"
                                :headers="headers_suche"
                                @GET_SELECTION="save_discipline"/>


                            <!--                          <v-select-->
                            <!--                              v-model="editedItem.WaffenDisziplin"-->
                            <!--                              :items="discipline"-->
                            <!--                              item-text="LangBez"-->
                            <!--                              item-value="Nummer"-->
                            <!--                              label="Disziplin"-->
                            <!--                              value="id"-->
                            <!--                              key="id"-->
                            <!--                              return-object-->
                            <!--                              outlined-->
                            <!--                              class="mx-1"-->
                            <!--                          >-->
                            <!--                            <template slot="selection" slot-scope="data">-->
                            <!--                              &lt;!&ndash; HTML that describe how select should render selected items &ndash;&gt;-->
                            <!--                              {{ data.item.Nummer }} {{ data.item.KurzBez }} - {{ data.item.LangBez }}-->
                            <!--                            </template>-->
                            <!--                            <template slot="item" slot-scope="data">-->
                            <!--                              &lt;!&ndash; HTML that describe how select should render items when the select is open &ndash;&gt;-->
                            <!--                              {{ data.item.Nummer }} {{ data.item.KurzBez }} - {{ data.item.LangBez }}-->
                            <!--                            </template>-->

                            <!--                          </v-select>-->
                            <!--                        </v-col>-->
                            <!--                      </v-row>-->
                            <!--                      <v-row>-->
                            <!--                        <v-col>-->
                            <!--                          <v-btn-->
                            <!--                              class="mx-1 mb-5 primary text&#45;&#45;white"-->
                            <!--                              text-->
                            <!--                              @click="addDiscipline()"-->
                            <!--                          >-->
                            <!--                            <v-icon class="mr-2">-->
                            <!--                              mdi-plus-->
                            <!--                            </v-icon>-->
                            <!--                            hinzufügen-->
                            <!--                          </v-btn>-->
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <WeaponDiscipline
                            :waffen_id="editedItem.weaponID">
                        </WeaponDiscipline>
                      </div>
                    </v-container>
                  </v-form>
                </v-card-text>
              </div>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                >
                  Abbrechen
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="save"
                >
                  Speichern
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Willst du diesen Eintrag wirklich löschen?
                <v-spacer></v-spacer>
                <v-btn icon @click="dialogDelete = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-actions>
                <v-btn color="blue darken-1" text @click="closeDelete">Abbrechen</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm()">OK</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <WeaponPDFExport
              :pdf_data="weapon"
              :name="comp_Name"
              :club_user="user_club"/>

          <v-btn icon
                 @click="get_Weapons">
            <v-icon>mdi-reload</v-icon>
          </v-btn>

        </v-toolbar>
      </template>
      <template v-slot:item.Erwerb="{ item }">
        <span>{{
            new Date(item.Erwerb.replace(" ", "T")).toLocaleString([], {
              dateStyle: 'short'
            })
          }}</span>


      </template>

      <template v-slot:item.export_weapon="{ item }">
        <v-icon
            v-if="item.export_weapon"
            color="success">
          mdi-check
        </v-icon>
        <v-icon
            v-if="!item.export_weapon"
            color="red">
          mdi-window-close
        </v-icon>
      </template>


      <template v-slot:item.actions="{ item }">
        <v-btn
            v-if="item.Transfered === 0 || item.Transfered === 2"
            class="info my-1 mx-1"
            text @click="editItem(item)">
          <v-icon>
            mdi-pencil
          </v-icon>
        </v-btn>

        <v-btn
            v-if="item.Transfered === 0 || item.Transfered === 2"
            class="error my-1"
            text @click="deleteItem(item)">
          <v-icon>
            mdi-delete
          </v-icon>
        </v-btn>

      </template>
      <template v-slot:item.transfer="{ item }">
        <v-btn
            v-if="item.Transfered === 0 "
            class="my-1"
            color="cyan lighten-1"

            @click="TransferWeaponOpen(item)">
          <v-icon>
            mdi-transfer-right
          </v-icon>
        </v-btn>
        <v-btn
            v-if="item.Transfered === 2 "
            class="my-1"
            color="blue lighten-1"

            @click="TransferWeaponReturnDialog(item)">
          <v-icon>
            mdi-transfer-left
          </v-icon>
        </v-btn>

      </template>
      <template v-slot:item.Transfered_Until="{ item }">

         <span
             v-if="item.Transfered === 1 || item.Transfered === 2">{{
             'bis: ' +
             new Date(item.Transfered_Until.replace(" ", "T")).toLocaleString([], {
               dateStyle: 'short'
             })
           }}</span>
        <br>
        <span
            v-if="item.Transfered === 1">{{
            'von: ' + item.Transfered_from_to
          }}</span>
        <span
            v-if="item.Transfered === 2">{{
            'an: ' + item.Transfered_from_to
          }}</span>

      </template>
      <template v-slot:no-data>
        <v-btn
            color="primary"
            @click="get_Weapons"
        >
          Reset
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import WeaponDiscipline from "../component/WeaponDiscipline";
import WeaponPDFExport from "@/views/component/WeaponPDFExport.vue";
import suchen_zuordnen from "@/views/component/suchen_zuordnen.vue";

export default {
  name: 'weaponTable',
  components: {suchen_zuordnen, WeaponDiscipline, WeaponPDFExport},
  props: {
    user_club: {
      type: String
    },
    data: {
      type: Object
    },
    weapon: {
      type: Array
    },
    discipline: {
      type: Array
    },
  },
  data: () => ({
    dialog: false,
    dialogDiszi: false,
    dialogDelete: false,
    modal: null,
    headers: [
      {
        align: 'KurzT',
        sortable: false,
      },
      {text: 'WBK', value: 'WBK'},
      {text: 'Beschreibung', value: 'Beschreibung'},
      {text: 'Hersteller', value: 'Hersteller'},
      {text: 'Kaliber', value: 'Kaliber'},
      {text: 'Kurz Text', value: 'KurzT'},
      {text: 'Lang Text', value: 'LangT'},
      {text: 'Nummer', value: 'Nummer'},
      {text: 'Erwerb', value: 'Erwerb'},
      {text: 'Export PDF', value: 'export_weapon', sortable: false, align: 'center'},
      {text: 'Actions', value: 'actions', sortable: false, align: 'center'},
      {text: 'Überlassung', value: 'transfer', sortable: false, align: 'center'},
      {text: 'Überlasssen', value: 'Transfered_Until', sortable: false},
    ],
    headers_suche: [
      {text: 'Verband', value: 'bund.Kurz'},
      {text: 'Nummer', value: 'Nummer'},
      {text: 'Kurz', value: 'KurzBez'},
      {text: 'Lang', value: 'LangBez'}
    ],
    editedItem: {
      Beschreibung: null,
      Hersteller: null,
      Kaliber: null,
      KurzT: null,
      LangT: null,
      Nummer: null,
      Erwerb: null,
      Erwerbformated: null,
      export_weapon: false,
      Gattung: {id: 1, value: 'KwGk'},
      WBK: null,
      NWRWID: null,
      NWREID: null,
      WaffenDisziplin: null,
      weaponID: null,
    },

    defaultItem: {
      Beschreibung: null,
      Hersteller: null,
      Kaliber: null,
      KurzT: null,
      LangT: null,
      Nummer: null,
      Erwerb: null,
      Erwerbformated: null,
      export_weapon: false,
      Gattung: {id: 1, value: 'KwGk'},
      WBK: null,
      NWRWID: null,
      NWREID: null,
      WaffenDisziplin: null,
      weaponID: null,
    },


    Gattung: [
      {id: 1, value: 'KwGk'},
      {id: 2, value: 'KwKk'},
      {id: 3, value: 'LP'},
      {id: 4, value: 'LG'},
      {id: 5, value: 'LwGk'},
      {id: 6, value: 'LwKk'},

    ],
    TextRules: [
      v => !!v || 'Eingabe erforderlich',
    ],
    NWRIDRules: [
      v => !!v || 'Eingabe erforderlich',
    ],
    KaliberRules: [
      v => !!v || 'Eingabe erforderlich',
    ],
    DatumRules: [
      v => !!v || 'Eingabe erforderlich',
    ],
  }),
  computed: {
    comp_AllDiscipline() {
      return this.$store.state.UserApp.Discipline;
    },
    comp_Name() {
      if (this.user_club == "UserApp") {
        return this.$store.getters['UserAuth/isUserName'];
      } else {
        return this.$store.getters['ClubAuth/isClubName'];
      }

    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    'editedItem.Erwerb': function (newVal) {
      if (typeof (newVal) !== 'object' && typeof (newVal) !== 'undefined') {
        this.editedItem.Erwerbformated = new Date(newVal.replace(" ", "T")).toLocaleString([], {dateStyle: 'short'});
      }
    },
  },
  methods: {
    get_Weapons() {
      this.$store.dispatch(this.user_club + '/getWeapons')
    },
    save() {
      if (this.$refs.form.validate()) {
        var Data = {
          inputWBK: this.editedItem.WBK,
          inputwaffenID: this.editedItem.weaponID,
          inputWGattung: this.editedItem.Gattung,
          inputWaffenNr: this.editedItem.Nummer,
          inputwaffenKaliber: this.editedItem.Kaliber,
          inputwaffenHersteller: this.editedItem.Hersteller,
          inputwaffenTk: this.editedItem.KurzT,
          inputwaffenTL: this.editedItem.LangT,
          inputwaffenBesch: this.editedItem.Beschreibung,
          inputDatErwerb: this.editedItem.Erwerb,
          inputNWRWID: this.editedItem.NWRWID,
          inputNWRWEID: this.editedItem.NWREID,
          inputExport: this.editedItem.export_weapon ? 1 : 0
        }
        this.$store.dispatch(this.user_club + '/setNewWeapon', Data)
            .then(this.dialog = false);
      }
    },

    editItem(item) {
      this.editedItem.WBK = item.WBK,
          this.editedItem.Beschreibung = item.Beschreibung,
          this.editedItem.Hersteller = item.Hersteller,
          this.editedItem.Kaliber = item.Kaliber,
          this.editedItem.KurzT = item.KurzT,
          this.editedItem.LangT = item.LangT,
          this.editedItem.Nummer = item.Nummer,
          this.editedItem.Erwerb = item.Erwerb,
          this.editedItem.export_weapon = item.export_weapon,
          this.editedItem.Erwerbformated = new Date(item.Erwerb.replace(" ", "T")).toLocaleString([], {dateStyle: 'short'}),
          this.editedItem.Gattung = item.Gattung
      this.editedItem.NWRWID = item.NWR_WID,
          this.editedItem.NWREID = item.NWR_EID,
          this.editedItem.weaponID = item.id

      if (this.user_club === 'UserApp') {
        this.dialogDiszi = true;
        this.$store.dispatch(this.user_club + '/getAllDiszi');
        // this.$store.dispatch(this.user_club + '/getDisciplineSingelWeapon', item.id);
      } else {
        this.dialogDiszi = false;
      }
      this.dialog = true;

    },

    save_discipline(item) {
      let Data = {
        waffenID: this.editedItem.weaponID,
        DisziID: item.id,
      }
      this.$store.dispatch(this.user_club + '/setDisciplinWeapon', Data)
    },

    addDiscipline() {
      let Data = {
        waffenID: this.editedItem.weaponID,
        DisziID: this.editedItem.WaffenDisziplin.id,
      }
      this.$store.dispatch(this.user_club + '/setDisciplinWeapon', Data)
    },

    close() {
      this.dialog = false
      this.dialogDiszi = false
      if (this.user_club === 'UserApp') {
        this.$store.commit(this.user_club + '/get_Diszi_Single_Weapons', []);
      }
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      if (this.$refs.form) {
        // Validierungsstatus explizit zurücksetzen
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
    },

    deleteItemConfirm() {
      let Data = {
        WeaponID: this.WeaponPrepareDelete.id
      };

      this.$store.dispatch(this.user_club + '/delWeapon', Data)
          .then(this.dialog = false, this.dialogDelete = false);
    },

    deleteItem(item) {
      this.WeaponPrepareDelete = item;
      this.dialogDelete = true;
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    TransferWeaponOpen(item) {
      this.$emit('TransferWeaponOpen', item)
    },

    TransferWeaponReturnDialog(item) {
      this.$emit('TransferWeaponReturnDialog', item)
    },

    itemRowClass(item) {
      var Class = null;
      switch (item.Transfered) {
        case 0:
          Class = '';
          break;
        case 1:
          Class = 'blue lighten-4';
          break;
        case 2:
          Class = 'cyan lighten-5';
          break
        default:
          Class = '';
      }
      return Class;
    },

  },
}
</script>

<style scoped>
.btn {
  width: 150px;
  text-align: left;
  margin-top: 2px;
  margin-bottom: 2px;
}

.scrollable-content {
  max-height: 800px; /* Feste Höhe festlegen */
  overflow-y: auto; /* Scrollbar hinzufügen */
}

</style>